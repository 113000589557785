import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const leadSchema: Schema = {
  ...createSchema({
    version: '1-1-0',
    description: 'Key information of the lead',
    name: 'lead',
    properties: {
      lead_id: {
        type: 'string',
        description: 'Unique identifer of the listing',
      },
      lead_type: {
        type: 'string',
        description: 'The type of lead created',
      },
      postcode: {
        type: 'string',
        description: 'The postcode of the user submitting the lead',
      },
      location: {
        type: 'string',
        description:
          'The location of where the lead form was when being submitted, e.g PDP, PLP, favourites etc',
      },
      trade_in: {
        type: 'string',
        description: 'The current vehicle the user wishes to trade in as part of the new purchase',
      },
      price: {
        type: 'number',
        description: 'The price of the vehicle when lead was submitted',
      },
      monthly_price: {
        type: 'number',
        description: 'The monthly price of the vehicle when the lead was submitted',
      },
      requested_datetime: {
        type: 'string',
        description: 'The day/time selected for a call back or view or appointment',
      },
    },
    required: ['price'],
  }),
};
